@import "../../assets/styles/variables.scss";

.profile-moderator-section {
  .profile-moderator-content {
    .main-title {
      text-transform: uppercase;
      text-align: center;
      font-family: "noto sans";
      font-size: 20px;
    }

    p {
      font-size: 16px;
      line-height: 21.79px;
      align-content: center;
      font-weight: 300;
      font-family: "noto sans";
      text-align: center;
      margin-bottom: 25px;
    }

    .primary-btn {
      display: flex;
      margin: 20px auto;
    }
    a {
      text-decoration: none;
    }
  }
}

@media(max-width:768px){
  .profile-moderator-section{
    .profile-moderator-content{
      p{
        font-size: 14px;
      }
    }
  }
}