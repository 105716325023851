@import "../../assets/styles/variables.scss";

.editor-section,
.card-shadow {
  border: 0 !important;

  .card-section {
    background-color: #91cea95e;
    padding: 0;

    .editor-text {
      color: $pitchGreen;

      span {
        font-weight: 700;
        margin-left: 5px;
        color: $pitchBlack;
      }
    }

    .back-color {
      background-color: $white;
      padding: 15px;
      border: 0;
      border-radius: 10px 10px 0 0;
    }

    .ck-editor-text {
      padding: 18px 25px 33px 25px;

      .white-block {
        padding: 5px 5px 5px 15px;
        background: white;
        border-radius: 5px;
        width: 100%;
        margin: 3px 20px 11px 0;

        span {
          i {
            font-size: 25px;
          }
        }
      }

      .content {
        margin-left: 0;
      }

      form {
        width: 100%;

        .white-block {
          width: 100%;
          outline: none;
          font-size: 15px;
          border: 0;
          padding: 7px 15px;
        }
      }

      .flex {
        flex-direction: row;
      }
    }
  }

  .flex {
    flex-direction: column;
    // display: flex;

    .comment-submit {
      background-color: transparent;
      text-transform: capitalize;
    }

    .popular-btn {
      border: 1px solid $gray;
      color: $gray;
      font-weight: 300;
      width: fit-content;
      margin: 6px 0;
      border-radius: 9px;
      font-size: 16px;
      letter-spacing: 1px;
      padding: 3px 15px;
    }
  }

  .explore {
    font-size: 20px;
    font-weight: 600;
  }

  .close-btns {
    background-color: $white;
    padding: 30px 25px;
    font-weight: 600;
    text-align: end;
    border-radius: 0 0 10px 10px;

    button {
      padding: 9px 35px;
      border-radius: 5px;
    }

    .primary-btn {
      color: $white;
      background-color: #9ec3ad;
      border: 1px solid #9ec3ad !important;

      &:hover {
        color: #9ec3ad !important;
        background-color: $white;
        border: 1px solid #9ec3ad !important;
      }
    }

    .main-btn {
      border: 1px solid !important;
      background-color: #86af8a;
      margin-right: 20px;
      padding: 10px 20px;
      border-radius: 5px;
      color: #ffffff;

      &:hover {
        background-color: #9ec3ad;
        color: $white;
        border: 1px solid #9ec3ad;
      }
    }
  }

  .errors {
    width: 100%;
    font-size: 12px;
    color: red;
    margin-left: 10px;
    margin-top: 10px;
  }

  // .popup-title {
  //   font-size: 16px;
  //   margin-top: 15px;
  //   margin-right: 28px;
  // }
}

.popup-padding {
  margin-top: 10px;

  padding: 10px;
}

.btn-block {
  .cancel-btn {
    background-color: #f9f9f9 !important;
    color: #919191 !important;
    padding: 10px 20px !important;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
  }
}

.popup-title {
  margin-bottom: 10px !important;
  margin-right: 60px !important;
}

.main-btn {
  margin-left: 10px;
  background-color: #86af8a;
  color: #fff;
}

.cross-btn {
  position: absolute;
  top: 7px;
  right: 15px;
}

@media (max-width: 768px) {
  .editor-section {
    padding-top: 0 !important;

    .flex-btns {
      flex-direction: row;
      flex-wrap: wrap;

      .popular-btn {
        margin-right: 30px;
        margin-bottom: 15px;
      }
    }

    .card-section {
      margin-bottom: 20px;

      .stance {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 480px) {
  .editor-section {
    .card-section {
      .ck-editor-text {
        .content {
          margin-bottom: 0;
          color: #000000;
          font-size: 17px;

          span {
            display: none;
          }
        }

        .details {
          display: none;
        }

        .white-block {
          margin-bottom: 28px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .editor-section {
    .flex-btns {
      .popular-btn {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
