
.verifying-page{
   
    .varification-card{
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
       .border-radius{
         border-radius: 50px;
       }
        .topic-one {
            text-align: center;
            font-weight: bold;
            font-size: 45px;
          }
        .navbar-brand {
            margin-right: 50px;
      
            img {
              width: 200px;
            }
          }
          .center-div {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .loading{
              width: 250px;
          }
          .mg-20{
            margin: 40px
          }
    }
}