@import "../../assets/styles/variables.scss";


.spinner-loader {}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    z-index: 1;
}