@import "../../assets/styles/variables.scss";

.reject-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    img {
        width: 300px;
        height: auto;
    }
}