@import "../../assets/styles/variables.scss";

.for-section {
  .for-content {
    background-color: $bgGrey;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    .d-flex{
      .red-bg{
        background-color: $red;
        color: #fff;
        border-color: $red;
        span{
          color: white;
        }
      }
      .green-bg{
        color: #fff;
        background-color: $primaryColor;
        span{
          color: white;
        }
      }
    }

    .sub-text {
      font-size: 15px;
      color: $black;
      margin: 10px;
    }

    // .btn-for {
    //   background: $primaryColor;
    //   color: #fff;
    //   border: 1px solid #86af8a;
    //   font-size: 15px;
    //   padding: 5px 23px;
    // }

    .btn-against .btn-udecided {
      color: $primaryColor !important;
    }

    .btn-points {
      color: $black;
    }

    .transform {
      i {
        transform: rotate(270deg);
        margin-right: 4px;
      }
    }

    // .popular-btn {
    //   font-size: 15px;
    //   font-weight: 600;
    //   letter-spacing: initial;

    //   i {
    //     font-weight: 400;
    //     padding: 4px 4px 0px 0px;
    //   }
    // }
  }

  .btn-see {
    background-color: $white;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    margin: 35px auto 0;
  }
}

@media (max-width: 480px) {
  .for-section {
    .for-content {
      background-color: white;
      box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
      flex-direction: column;
      .sub-text{
        margin: 10px 0;
      }
      .popular-btn {
        padding: 6px;
      }
    }
  }
}

.icon-likes {
  margin-right: 5px;
}

.icon-dislikes {
  margin-right: 5px;
}

@media (max-width: 375px) {
  .for-section {
    .for-content {
      .popular-btn {
        padding: 2px;
      }
      .d-flex{
        flex-direction: column;
        width: fit-content;
        margin: auto;
        .btn-for{
          margin-bottom: 15px;
        }
      }
    }
  }
}
