@import "../../assets/styles/variables.scss";



.tab-panel-section{

    .MuiTab-textColorInherit {
        color: $black;
        font-size: 15px;
        text-transform: none;
    }
    
    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
        color: $black;
        text-transform: none;
        font-size: 14px;
    }
    .MuiAppBar-colorPrimary {
        
        background-color: #ffffff !important;
        box-shadow: none !important;
    }
    
    .MuiTab-root {
        min-width: 90px !important;
    }
    
    .PrivateTabIndicator-colorSecondary-4 {
        background-color: $primaryColor !important;
        width: 90px;
    }
}