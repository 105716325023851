@import "../../assets/styles/variables.scss";

.rulespolicies-section {
  .rulespolicies-content {
    padding: 25px;
    .main-title {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      line-height: 27px;
    }

    .moderator-text {
      margin-bottom: 25px;
      h1 {
        font-size: 18px;
        margin-bottom: 10px;
        color: $fontGray;
      }

      h2 {
        font-size: 15px;
        color: $gray;
      }

      ul {
        li {
          font-size: 14px;
          color: $gray;
        }
      }

      p {
        font-size: 14px;
        color: $gray;
      }
    }

    a {
      color: $primaryColor;
      font-size: 16px;
      float: right;
    }

    a:hover {
      color: $darkGreen;
    }
  }
}

@media (max-width: 375px) {
  .rulespolicies-section {
    .rulespolicies-content {
      padding: 10px;
    }
  }
}
