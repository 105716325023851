@import "../../assets/styles/variables.scss";

.sub-category-section {
  .banner {
    // background-image: url("https://img.rasset.ie/000e6650-1600.jpg");
    // object-fit: cover;
    height: 230px;
    background-position: center;
    background-size: cover;
    width: 100%;

    .banner-text {
      font-weight: 900;
      font-size: 40px;
    }
  }

  .sub-sections {
    display: flex;
    padding: 20px 0 0 0;
    flex-wrap: wrap;

    .border-img {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 8.33%;
      margin-top: 20px;

      &:nth-child(-n+12) {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
      }

      img {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        object-fit: cover;
        object-position: center;
        margin-bottom: 5px;
      }

      .content {
        color: $title-color;
      }
    }
  }
}

@media (max-width: 768px) {
  .sub-category-section {
    .sub-sections {
      .border-img {
        width: 11.33%;
      }
    }
  }
}

@media (max-width: 480px) {
  .sub-category-section {
    .sub-sections {
      .border-img {
        width: 19.33%;

        img {
          height: 70px;
          width: 70px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .sub-category-section {
    .sub-sections {
      .border-img {
        width: unset;
        padding-right: 15px;
      }
    }
  }
}