@import "../../assets/styles/variables.scss";

.stance-page{
    position: relative;
    .stance-section{
        position: relative;
    }
    .stance-wrapper{
        // position: absolute;
        // height: auto;
        // width: -webkit-fill-available;
        // top: 225px;
        // bottom: 0;
      .stance-description{
        position: relative ;
        bottom: 0;
        top: -77px;
        height: auto;
      }
      .opnion-section {
        margin-top: -65px;
      }
    }
  }

  @media(max-width: 480px){
    .stance-page{
      .stance-wrapper{
        .stance-description{
          top: -103px;
        }
        .opnion-section{
          margin-top: -103px;
        }
      }
    }
  }