@import "../../assets/styles/variables.scss";



.subcomment-edit {
    padding: 0px;

    .btn-block {
        margin-top: 15px;
    }

    .cancel-btn {
        margin-left: 0px;
    }

    .popup-title {

        color: $fontGray;


    }

    .popup-icons {
        margin-left: 10px;
        // margin-top: 10px;




    }


}

.btn-line {
    margin-left: -15px;

}

.popup-title {
    margin-left: 11px !important;
}

.popup-edit {
    margin-left: 10px;


    color: $blue-color;
}

.title-popup {
    margin-left: 16px;
    margin-top: 10px;
    font-size: 16px;
}

.btn-action {
    margin-top: 5px;
}

.popup-delete {
    margin-left: 10px;
    color: $red;
}

.subcomment-edit {
    .edit-popup {
        margin-bottom: 20px;
    }
}