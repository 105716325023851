@import "../../assets/styles/variables.scss";

.popup {
  .form-section {
    .space-between {
      .close-btn {
        font-size: 16px;
        padding: 0;
        font-weight: 500;
        padding-bottom: 5px;
      }
    }
  }
.scroll-bar{
  height: 240px;
}
  .popup-modal {
    height: auto;
  }

  .social-media {
    margin-top: 20px;

    .social-btn-block {
      margin-bottom: 10px;
      display: flex;

      img {
        height: 42px;
        width: 42px;
        object-fit: contain;
        object-position: center;
        border-radius: 5px 0 0 5px;
      }

      .social-btn {
        padding: 10px 20px;
        font-size: 12px;
        border: none;
        color: $white;
        display: flex;
        justify-content: center;
        background: #405ba9;
        border-radius: 0 5px 5px 0;
        width: 100%;

      }

      .google-btn {
        background: #336cf0;
        border: 1px solid #336cf0
      }
    }



  }

  .btn-content {
    font-size: 12px;
    border: 1px solid #405ba9;
    margin-bottom: 10px;
    color: #fff;
    background: #405ba9;
    padding: 11px;
    width: 100%;
    display: flex;
    // align-items: center;
    // justify-content: center;
    border-radius: 0 5px 5px 0;
    padding-left: 30px;
  }


  .login-btn {
    width: 100%;
    margin: 20px 0 10px;
    border: 1px solid #86af8a;
    color: #fff;
    background: #86af8a;
    // padding: 5px;
    border-radius: 5px;
  }

  .btn-contents {
    font-size: 12px;
    border: 1px solid #405ba9;
    margin-bottom: 10px;
    color: #fff;
    background: #336cf0;
    padding: 9px;
  }

  .fb-icon {
    padding: 11px 16px;
    background: #3a5298;
    color: #fff;
  }

  .form-section {
    // max-height: 400px;
  }

  .title {
    color: #2e2e2e;
    font-size: 12px;
    font-weight: $bold;
  }

  .google-icon {
    background: #fff;
    color: #fff;
    padding: 7px 10px;
    border: 2px solid #336cf0;
    border-radius: 5px;
  }

  .dotted-line {
    line-height: 0;
    border-bottom: 1px dashed #86af8a;



    margin: 8px 0 20px;

    span {
      display: flex;
      justify-content: center;
      background: white;
      padding: 0 10px;
      text-align: center;
    }
  }

  .dotted-items {
    color: #2e2e2e;
    font-weight: bold;
    font-size: 14px;
  }



  .show-icon {
    color: #919191;
    top: 86px;
    right: 31px;
    position: absolute;
  }

  .sign-up {
    margin-right: 7px;
    color: #86af8a;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
  }

  .align-itemss {
    font-weight: $bold;
    color: #2e2e2e;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .password-block {
    position: relative;

    span {
      position: absolute;
      right: 10px;
      color: gray;
      padding: 4px 0;
    }
  }

  .space-between {
    .pl {
      padding-left: 10px;
      font-size: 10px;
      font-weight: 600;
      color: #919191;
    }

    .cg {
      cursor: pointer;
      font-size: 10px;
      font-weight: 600px;
      font-style: normal;
      color: $primaryColor;
    }
  }

  .flex {
    align-items: center;
    justify-content: center;
  }

  .checkbox-label {
    display: block;
    position: relative;
    width: auto;
  }

  .checkbox-label input {
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-label .checkbox-custom {
    position: absolute;
    left: 0px;
    height: 17px;
    width: 17px;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 1px solid gray;
    top: 2px;
  }

  .checkbox-label input:checked~.checkbox-custom {
    position: absolute;
    left: 0px;
    height: 17px;
    width: 17px;
    background-color: $primaryColor;
    border-radius: 3px;
    border: 1px solid $primaryColor;
  }

  .checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  .checkbox-label input:checked~.checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
    top: 2px;
    left: 5px;
  }
}

.btn {
  padding-left: 0 !important;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 10px;
  font-style: normal;
}

// h6 {
//   position: relative;
//   font-size: 16px;
//   z-index: 1;
//   overflow: hidden;
//   text-align: center;
// }

// h6:before,
// h6:after {
//   position: absolute;
//   top: 51%;
//   overflow: hidden;
//   width: 50%;
//   height: 1px;
//   content: "\a0";
//   background-color: #86af8a;
// }

// h6:before {
//   margin-left: -50%;
//   text-align: right;
// }

h6 {
  border-collapse: separate;
  border-spacing: 16px 0;
  border-spacing: 1rem 0;
  color: #2e2e2e;
  display: table;
  font-size: 5em;
  line-height: 0.25;
  margin: 1em 0 0.5em;
  table-layout: auto;
  text-align: center;
  text-shadow: 0.0625em 0.0625em 0 rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  width: 100%;
}

h6:before,
h6:after {
  border-top: 1px dashed #86af8a;
  content: "";
  display: table-cell;
  width: 50%;
}

h6 {
  font-weight: bold;
  font-size: 1em;
}

h6:before,
h6:after {
  border-top-style: dotted;
}