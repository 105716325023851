.field-content {
    .card-section {
        .form-group {
            textarea {
                height: 100px;
                resize: none;
            }
        }
    }


}