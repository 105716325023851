@import "../../assets/styles/variables.scss";


.stance-description {
  position: absolute;
  top: 300px;
  z-index: 1111;
  position: absolute;
  width: 100%;
}

.custom-section {

  .card-section {
    // position: relative;
    bottom: 80px;
    margin-bottom: 10px;

    .space-between {
      .description {
        padding: 7px 10px 0 11px;
        line-height: 1.1;

        .date {
          // margin-left: 24px;
          font-size: 15px;
        }

        .name {
          text-transform: capitalize;
          color: #00324b;
          font-weight: 500;
          font-size: 16px;
          font-style: normal;
        }
      }
    }

    .picture {
      width: 65px;
    }

    .user-name {
      margin-bottom: 0;
      font-size: 24px;
    }

    .datee {
      font-size: 14px;
      color: #919191;
    }

    .title-img {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    .created-date {
      margin-left: 53px;
      margin-bottom: -5px;
    }

    .custom-text {
      color: $pitchBlack;
      padding: 15px 0;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 0;
    }

    .show-block {
      display: none;
    }

    .gray-text {
      line-height: 1.6;

      .read-more {
        color: $pitchBlack;
        font-size: 11px;
        margin-left: 5px;
      }
    }

    .display {
      display: flex;

      .view-icon {
        font-size: 14px;
        padding: 0 15px 0 0;

        i {
          background: #2323237a;
          border-radius: 50%;

          color: $white;
        }

        .number {
          padding-left: 5px;
          font-weight: 500;
          font-size: 15px;
        }
      }

      .dislike-color {
        i {
          &:hover {
            cursor: pointer;
            background-color: $darkRed;
          }
        }
      }

      .link-color {
        color: $white !important;

        i {
          background-color: $select;

          &:hover {
            cursor: pointer;
            background-color: $likeGreen;
          }
        }
      }

      .active-like {
        i {

          background-color: $likeGreen;
        }
      }

      .active-dis-like {
        i {

          background-color: $darkRed;
        }
      }
    }
  }
}

.opnion-section {
  .card-section {
    .comment {
      display: flex;
      padding: 10px 0;

      img {
        width: 25px;
        height: 25px;
        margin: 11px 8px 0 0;
      }


    }

    textarea {
      outline: none;
      border: 0;
      padding: 0 !important;
      background-color: $lightGray;
      border-radius: 10px;
      resize: none;
      width: 100%;
      height: 30px;
      font-size: 15px;
      padding: 15px;
    }
  }

  .MuiGrid-spacing-xs-2 {
    margin: -8px 0;
  }

  .MuiGrid-root {
    position: relative;
    margin: 0;
  }

  .MuiGrid-spacing-xs-3 {
    margin: 0 !important;
  }

  .border-right {
    position: absolute;
    border-right: 1px solid $primaryColor;
    height: 368px;
    top: 608px;
    left: 658px;
  }

  .small-circle {
    top: 596px;
    width: 12px;
    height: 12px;
    left: 653px;
  }


  .grid-section {
    .card-section {
      left: -17px;
      bottom: 99px;
    }
  }

  .arrow {
    position: relative;
    bottom: 80px;
    color: #59ec47;
    background: #00da0726;
    width: 50%;
    text-align: end;
    padding: 10px 35px 10px 15px;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
    left: 263px;
  }

  .for {
    clip-path: polygon(0 0, 90% 0, 89% 0, 100% 50%, 90% 99%, 91% 100%, 0 100%);
  }

  .against {
    text-align: start;
    left: 20px;
    padding: 10px 15px 10px 35px;
    color: #d00e56;
    background: #ff00001f;
    clip-path: polygon(12% 0,
        12% 0,
        100% 0,
        100% 100%,
        14% 99%,
        13% 100%,
        0% 50%);
  }

  .block {
    display: none;
  }

  .comment-section {
    display: flex;


    .comment-image {
      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
      }
    }

    .bx-icons {
      margin-left: 10px;
    }

    .edit-icon {
      color: $fontGray;
      margin-right: 10px;
    }

    .del-icon {
      color: $red;
    }



    .comment-box {
      position: relative;
      width: 100%;
      margin-left: 10px;

      &::before {
        content: "";
        border-style: solid;
        border-width: 8px 13px 13px 3px;
        border-color: transparent $bgGrey transparent transparent;
        position: absolute;
        left: -15px;
        top: 8px
      }
    }

    .comment-field {
      width: 100%;
      border-radius: $border-radius;
      padding: 10px;
      box-sizing: border-box;
      position: absolute;
      background: $bgGrey;
      color: $content-color;
      font-size: 16px;
      border: none;

      &:focus {
        outline: none;
      }
    }


    .comment-block {
      width: 100%;
      border-radius: $border-radius;
      padding: 10px;
      box-sizing: border-box;
      // position: absolute;
      background: $bgGrey;
      color: $content-color;
      font-size: 12px;
      border: none;

      .content {
        font-size: 12px;
      }

      .comment-info-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        .name {
          margin: 0;
          color: #00324b;
          font-size: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            color: $content-color;
          }

          .dot {
            height: 5px;
            width: 5px;
            background: $content-color;
            border-radius: 50%;
            margin: 0 5px 0 10px;
          }
        }
      }
    }


  }

  .comment-area {

    .comment-section {
      margin-bottom: 10px;
    }
  }

  .comment-transition {
    margin-top: 100px;
    -webkit-transition: 1s;
  }
}

.for-component {
  .key-argument {
    font-weight: bold;
    color: $fontGray;
  }
}

@media (max-width: 992px) {
  .opnion-section {
    .arrow {
      left: 222px;
    }

    .against {
      left: 8px;
    }

    .circle {
      left: 460px;
    }

    .small-circle {
      left: 477px;
    }

    .border-right {
      left: 480px;
    }
  }

  .custom-section {
    .card-section {
      .user-name {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .opnion-section {
    .arrow {
      width: 30%;
      padding: 0px 35px 0px 15px;
      left: 0;
    }

    .against {
      padding: 0px 15px 0px 35px;
    }

    .card-section {}

    .border-right,
    .circle,
    .small-circle {
      left: unset;
      right: -19px;
    }

    .mr-20,
    .ml-20 {
      margin: 0;
      margin-bottom: 15px;
    }

    .hidden {
      display: none;
    }

    .block {
      display: block;
      float: right;
    }

    .center {
      display: flex;
      justify-content: center;
    }

    .more {
      position: relative;
      top: -90px;
      margin: 10px 0;
    }

    .mh-0 {
      max-height: 0;
    }
  }

  .custom-section {
    .card-section {
      .datee {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 480px) {
  .opnion-section {
    .arrow {
      width: 45%;
    }

    .border-right,
    .circle,
    .small-circle {
      display: none;
    }
  }

  .custom-section {
    .card-section {
      .hidden-block {
        display: none;
      }

      .show-block {
        display: block;
      }
    }
  }
}

.btn-like {
  &:hover {
    cursor: pointer;
  }
}

.btn-dislike {
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 375px) {
  .opnion-section {
    .arrow {
      width: 53%;
      bottom: 65px;
    }

    .against {
      bottom: 68px;
    }

    .more {
      top: -78px;
    }
  }

  .custom-section {
    .card-section {
      bottom: 65px;
    }
  }
}