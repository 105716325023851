@import "../../assets/styles/variables.scss";

.popular-section {
  .popular-title {
    display: flex;
    font-size: 25px;
    padding-bottom: 30px;

    p {
      padding: 0 45px 0 0;
    }
  }

  .card-section {
    margin-bottom: 20px;
  }

  .sub-title {
    font-size: 24px;
    color: $black;
    font-weight: 500;
  }

  .popular-img {
    height: 230px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }

  .place-holder {
    background: $primaryColor;
    color: $white !important;
    display: flex;
    flex-direction: column;
    ;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  }

  .space-between {
    p {
      margin-bottom: 0;
    }

    button {
      text-transform: uppercase;
    }
  }

  .sub-text {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0 5px 0;
  }

  .time {
    color: $faintGray;
    font-size: 14px;
  }

  .agree {
    margin: 20px 0 17px 0;

    button {
      letter-spacing: 0;
    }
  }

  .views {
    display: flex;

    i {
      padding: 0;
      color: $content-color;
    }

    p {
      margin-bottom: 0;
    }
    .number{
      padding: 0;
    }
  }

  .hide-block {
    display: none;
  }
}
 .see-more-btn{
   .see-more{
     display: none;
   }
 }
@media (max-width: 768px) {
  .popular-section {
    .popular-img {
      height: 150px;
    }

    .hide-block {
      display: block;
    }

    .show-block {
      display: none;
    }
  }
}

@media(max-width:768px){
 .popular-section{
   .sub-text{
     font-size: 18px;
   }
   .time{
     font-size: 12px;
   }
 }
}

@media (max-width: 480px) {
  .popular-section {
    .popular-img {
      height: 125px;
    }
    .place-holder{
      font-size: 16px;
    }
    .sub-text{
      font-size: 16px;
    }
    .cat-label{
      font-size: 10px;
    }
    .view-icon{
      font-size: 14px;
    }
  }
}

@media(max-width:375px){
  .popular-section{
    .popular-img{
      height: 80px;
    }
    .place-holder{
      font-size: 14px;
    }
    .sub-text{
      padding: 15px 0 10px ;
      margin: 0;
    }
    .gray-text{
      margin: 0;
    }
    .agree{
      margin: 15px 0;
    }
    .pr-15{
      padding: 0;
    }
  }
}