@import "../../assets/styles/variables.scss";

.debate-section {
  .main-title {
    color: $black;
    font-size: 20px;
  }

  .debate-content {
    padding: 20px;

    .category-section {
      margin-bottom: 15px;

      p {
        font-size: 18px;
        margin-bottom: 5px;
        i {
          color: #ff0606;
          font-size: 14px;
        }
      }

      .select-shadow {
        width: 100%;
        padding: 10px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 4px 0px;
        border: 2px solid #fff;
        background-color: #fff;
        border-radius: 10px;
        font-size: 13px;
        color: $select;
      }

      .radio-select {
        margin-right: 20px;

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 15px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: $black;
        }

        /* Hide the browser's default radio button */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid grey;
          height: 20px;
          width: 20px;
          background-color: #0000ff00;
          border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input~.checkmark {
          background-color: #fff;
        }

        /* When the radio button is checked, add a blue background */
        .container input:checked~.checkmark {
          background-color: #fff;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .container input:checked~.checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $primaryColor;
        }
      }

      .ck-section {
        .ck .ck-toolbar {
          background-color: $bgGrey;
          border: 2px solid #80808017;
        }

        .ck.ck-editor__editable_inline {
          background-color: $bgGrey;
          height: 220px;
        }
      }
    }

    .stance-radio {
      display: flex;
      align-items: center;

      label {
        width: unset;
        margin-bottom: 3px;
        padding-right: 70px;

        .text-sm {
          padding-left: 5px;
        }
      }

      .space {
        display: inline-block;
        position: relative;
        padding: 0 6px;
        margin: 10px 0 0;
      }

      .space input[type='radio'] {
        display: none;
      }


      .space span:before {
        content: " ";
        display: inline-block;
        position: relative;
        top: 2px;
        margin: 0 5px 0 0;
        width: 15px;
        height: 15px;
        border-radius: 11px;
        border: 1px solid gray;
        background-color: transparent;
      }

      .space input[type=radio]:checked+span:after {
        border-radius: 11px;
        width: 9px;
        height: 9px;
        position: absolute;
        top: 8px;
        left: 14px;
        content: " ";
        display: block;
        background: $primaryColor;
      }
    }

    .text {
      input {
        border: none;
        background-color: $bgGrey;
        width: 100%;
        padding: 15px;
        height: 45px;
        border-radius: 5px;
        font-size: 13px;
      }

      input:focus-visible {
        border: none !important;
      }
    }

    .btn-for {
      font-weight: 600;
      margin: 10px 0 0 0;
      font-family: "Noto Sans";
      letter-spacing: 0px;
    }
  }
  .image-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-size: 18px;
      margin-bottom: 5px;
      i.optional {
        margin-left: 20px;
        font-size: 14px;
        color: #747779;
      }
    }
    .error{
      margin-top:5px;
    }
  }
}

@media (max-width: 480px) {
  .debate-section {
    .main-title {
      font-size: 18px;
    }

    .debate-content {
      .category-section {
        p {
          font-size: 15px;
        }
      }
    }

    .choose-file {
      font-size: 15px;
    }
  }
}

@media (max-width: 375px) {
  .debate-section {
    .debate-content {
      .category-section {
        .d-flex {
          flex-direction: column;
        }
      }
    }
  }
}