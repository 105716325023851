@import "../../assets/styles/variables.scss";

.become-moderator-section {
  .hide{
    display: none;
  }
  .main-title {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: "Noto sans";
  }

  .become-moderator-content {
    background-color: $bgGrey;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
  }

  .flex-column {
    width: 150px;

    .styled-checkbox {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        border: 1px solid #0000005e;
        border-radius: 3px;
        height: 25px;
        width: 23px;
        margin-left: 30px;
      }
      .moderator-content {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        margin-bottom: 30px;
      }

      // Box.
      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 21px;
        height: 21px;
        background: white;
      }

      // Box hover
      &:hover + label:before {
        background: white;
      }

      // Box checked
      &:checked + label:before {
        background: $primaryColor;
      }

      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
          4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
      }
    }

    p {
      font-size: 14px;
    }
  }

  .moderator-text {
    width: calc(100% - 150px);

    h1 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 15px;
    }

    p {
      font-size: 14px;
    }
  }

  h4 {
    color: $darkBlack;
    font-size: 16px;
    font-weight: 500;
  }

  .btn-for {
    color: #86af8a !important;
    text-align: center;
    display: flex;
    margin: 30px auto;
    font-weight: 600;
  }
}

.btn-submit-div {
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .become-moderator-section {
    .main-title {
      font-size: 20px;
    }
    .moderator-text{
      h1{
        font-size: 18px;
      }
      h2{
        font-size: 14px;
      }
      .sub-text{
        font-size: 14px;
      }
    }
    h4{
      font-size: 14px;
    }
  }
}
@media (max-width: 480px) {
  .become-moderator-section {
    .main-title{
      font-size: 18px;
    }
    .d-flex{
      flex-direction: column;
      .display{
        display: flex;
        align-items: center;
      }
    }
    .hide{
      display: block;
    }
    .show{
      display: none ;
    }
    .flex-column {
      width: 50%;

      input {
        width: 85%;
      }
      p{
        font-size: 12px;
      }
    }

    .moderator-text {
      width: 100%;
      h1{
        font-size: 14px;
      }
      .sub-text, h2{
        font-size: 12px;
      }

    }
  }
}

@media (max-width: 375px) {
  .become-moderator-section {
    .become-moderator-content {
      .d-flex {
        flex-direction: column;
        .flex-column {
          display: flex;
          width: 100%;
          margin-bottom: 10px;

          .styled-checkbox {
            & + label {
              margin: 0;
            }
          }

          p {
            margin: 5px 0;
          }
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .become-moderator-section {
    .hide{
      display: none;
    }
    .show{
      display: block;
    }
  }
}