
.react-tabs__tab--selected {
    background: transparent;
    border-color: transparent;
    // color: ;
    // border-radius: ;
    border-bottom: 3px solid #86af8a;
    color:#86af8a !important;
    // border-right: none;
    // border-top: 0;
    // border-left: 0;
}
.react-tabs__tab:focus {
    box-shadow: none;
    border-color: transparent;
}
.react-tabs__tab-list {
    border-bottom: 1px solid transparent;
}
.react-tabs__tab {
    padding: 10px 20px;

}
.tab-component{
    .react-tabs__tab{
        font-size: 20px;
    }
}

@media(max-width:480px){
    .tab-component{
        .react-tabs__tab{
            font-size: 16px;
        }
    }
}