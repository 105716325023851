$primaryColor: #86af8a;
$black: rgba(0, 0, 0, 0.55);
$white: #fff;
$lightGray: #f8f9fa;
$darkGreen: #289028bb;
$gray: gray;
$blue: #0000ff7d;
$lightBlue: #7cecc838;
$pitchBlack: #000000d4;
$faintGray: #9a9696d4;
$navyBlue: #37b3d0f0;
$bgGrey: #f5f5f5;
$linkColor: #0000ff;
$pitchGreen: #136d13;
$darkBlack: #000000f0;
$fontGray: #2e2e2e;
$select: #919191;
$red: #ff0000ad;
$shadow: 0 0 60px 0 #00000033;
$border-radius: 10px;
$blue-color: #3e67de;
$bg-color: #f9f9f9;
$content-color: #666666;
$title-color: #2e2e2e;
$lightGreen: #d0f6d4;
$limeGreen: #28de3a;
$lightPink: #ffdfdf;
$darkPink: #de1254;
$greenColor: #86af8a;
$likeGreen: #00bf91;
$darkRed: #fd2121;
$lightGrey:#FAFAFA;
//font
$bold: 600;