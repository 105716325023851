@import "../../assets/styles/variables.scss";

.cancel-btn {
  margin-left: 17px;
  color: $faintGray;
  padding: 6px 20px;
  border: 1px solid $faintGray;
  background-color: transparent;
  border-radius: 8px;
  font-size: 14px;
}
.bottom-btn {
  display: flex;
  justify-content: end;
  margin-top: 7px;
}

.email-title {
  font-weight: bold;
  font-size: 12px;
}

.forget-pw {
  padding: 20px;
}
