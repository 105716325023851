@import "../../assets/styles/variables.scss";

.myprofile-section {
  .black {
    color: #2e2e2e !important;
    font-size: 20px;
    font-weight: bold;
  }

  .myprofile-content {
    .popup {
      float: right;
    }

    .btn-for {
      border-radius: 5px;
      font-weight: 600;
      float: right;
      color: $white !important;
    }

    .profile-info {
      .img-content {
        width: 80px;
        // height: 80px;
        position: relative;
        margin-right: 10px;
        display: flex;
        align-items: center;

        .user-img,
        .user-icon {
          height: 80px;
          width: 80px;
          object-fit: cover;
          border-radius: 50%;
          position: relative;
          background: $primaryColor;
          color: $white;
          font-size: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon-img {
          position: absolute;
          height: 25px;
          width: 25px;
          bottom: 4px;
          right: 4px;
        }
      }

      .info-content {
        width: calc(100% - 80px);

        .debate-text {
          color: $primaryColor;
          font-size: 14px;
          margin-left: 3px;

          img {
            margin-right: 5px;
          }
        }

        .profile-text {
          font-size: 20px;
          font-weight: bold;
          padding: 18px 0 0 0;
          color: $pitchBlack;
          text-transform: capitalize;
        }
      }
    }

    .profile-sub-text {
      font-size: 16px;
      color: $fontGray;
    }

    .profile-points {
      .profile-sub-text {
        padding: 18px 0 0;
        margin-bottom: 0;
      }
    }
  }

  .sub-content {
    img {
      object-fit: contain;
      width: 15%;
      padding: 10px 0px 0 0;
    }

    .profile-points {
      width: calc(100% - 10%);
      margin-left: 8px;
    }
  }
}

@media (max-width: 992px) {
  .myprofile-section {
    .myprofile-content {
      .profile-info {
        .d-flex {
          align-items: center;
        }

        .img-content {
          width: 60px;
          height: 60px;

          img {
            height: 60px;
            width: 60px;
          }
        }

        .info-content {
          width: calc(100% - 60px);
        }
      }
    }
  }
}

.birth-date {
  font-size: 20px;
  font-weight: 600;
}

.for-section .for-content .for-title {
  p.sub-text {
    font-size: 14px;
    color: $fontGray;
    font-weight: normal;
  }
}

@media (max-width: 768px) {
  .myprofile-section {
    .myprofile-content {
      .space-between {
        flex-direction: column;

        .sub-content {
          margin-bottom: 5px;
        }
      }

      .profile-info {
        .img-content {
          img {
            width: 50px;
            height: 50px;
          }
        }

        .info-content {
          .profile-text {
            font-size: 16px;
          }
        }
      }

      .profile-sub-text {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 480px) {
  .myprofile-section {
    .sub-content {
      img {
        width: 32px;
      }
    }

    .myprofile-content {
      .profile-info {
        .img-content {
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .space-between {
      display: block;

      .primary-btn {
        float: right;
      }
    }
  }
}

@media (max-width: 480px) {
  .profile-info {
    .img-content {

      .user-img,

      .user-icon {
        height: 55px !important;
        width: 55px !important;
      }
    }
  }

}

@media (max-width: 375px) {
  .myprofile-section {
    .sub-content {
      .profile-points {
        .birth-date {
          font-size: 17px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .myprofile-section {
    .sub-content {
      img {
        width: 16px;
      }

      .profile-points {
        .birth-date {
          font-size: 15px;
        }
      }
    }
  }
}