@import "../../assets/styles/variables.scss";

.explore-section {
  .head-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .categories {
    z-index: 2;
    font-size: 28px;
    text-transform: capitalize;
    color: #fff;
    position: absolute;
    margin: 0;
    font-weight: 700;
    text-align: center;
    word-break: break-all;
  }
  .mg-top{
    margin-top: 20px;
  }
  img {
    border-radius: 10px;
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
  .category-content {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .child {
    margin-top: 24px;
    &:nth-child(-n + 4) {
      margin-top: 0;
    }
  }
  .breadcrumb-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: #8e8a8a47;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .explore-section {
    .categories {
      font-size: 24px;
      word-break: break-all;
    }
    .breadcrumb-overlay {
      height: 115px;
    }
    img {
      height: 115px;
    }
  }
}

@media (max-width: 768px) {
  .explore-section {
    .categories {
      font-size: 20px;
      word-break: break-all;
    }
    .child {
      &:nth-child(4) {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 480px) {
  .explore-section {
    .categories {
      font-size: 15px;
      word-break: break-all;
    }
    .breadcrumb-overlay {
      height: 100px;
    }
    img {
      height: 100px;
    }
    .child {
      &:nth-child(4) {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 375px) {
  .explore-section {
    .categories {
      font-size: 13px;
      word-break: break-all;
    }
    img {
      height: 50px;
    }
    .breadcrumb-overlay {
      height: 50px;
    }
  }
}
