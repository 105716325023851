@import "../../assets/styles/variables.scss";

.notification {
  width: 70%;
  background: $white;
  padding: 20px 70px;
  box-shadow: 0 0 60px 0 #00000033;
  border-radius: 10px;

  .space-between {
    .pt-6 {
      padding-top: 6px;
    }
  }

  .notification-card {
    .card-body {
      padding: 10px;
      justify-content: unset;

      .title-color {
        margin-left: 3px;
      }

      .title-img {
        .picture {
          position: relative;

          .agree-icon {
            position: absolute;
            top: 36px;
            left: 32px;

            .b-circle {
              background-color: #00BF91;
              border-radius: 50%;
              height: 22px;
              width: 22px;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                color: white;
                font-size: 15px;
              }

              .tick {
                font-size: 18px;
                font-weight: 600;
                padding: 2px;
              }
            }
          }

          .image-uploads {
            border-radius: 50%;
            background-color: rgba(145, 145, 145, 0.52);
            margin-right: 15px;
            object-fit: cover;
            max-width: 55px;
            min-width: 55px;
            max-height: 55px;
          }

          .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            color: $white;
            background-color: $primaryColor;
          }
        }
      }
    }
  }

  .view {
    color: $blue-color;
  }

  .primary-color {
    color: $primaryColor;
  }

  .notification-section {
    padding: 10px;
    background-color: $bg-color;
    border-radius: $border-radius;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .notification-image {
      position: relative;

      img {
        height: 65px;
        width: 65px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }

      .verify-icon {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: $primaryColor;
        color: $white;
        display: grid;
        place-items: center;
        position: absolute;
        top: 42px;
        right: -3px;
        font-size: 14px;

        i {
          font-size: 13px;
          color: $white;
        }

        .tick {
          font-size: 17px;
        }
      }
    }

    .mb-0 {
      margin-bottom: 0;
    }

    .notification-block {
      width: calc(100% - 60px);
      margin-left: 15px;

      .small-content {
        font-size: 15px;
      }

      .time {
        margin: 20px 0 5px 0;
        color: $blue-color;
      }
    }
  }
}

.show {
  display: block;
}

.bold {
  font-size: 13px;
  color: #196e20;
}

.time-hour {
  color: #3e67de;
  font-size: 10px;
  font-weight: 500;
}

.notification-name {
  font-size: 16px;
  font-weight: bold;
  color: $fontGray;
  text-transform: capitalize;
}

.notifications {
  background: rgba(145, 145, 145, 0.1);
  border-radius: 10px;
}

.description {
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.notification-card {
  margin-bottom: 10px;
  border-color: transparent;
}

.header-notification {
  .notification {

    max-height: 550px;
  }

}

.notification-single-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .section-gap {
    @extend .notification-single-page;
  }
}

@media(max-width:768px) {
  .notification {
    padding: 20px 50px;
  }
}

@media(max-width:480px) {
  .description {
    font-size: 12px;
  }

  .notification {
    width: 100%;
    padding: 20px;

    .notification-card {
      .card-body {
        .title-img {
          .picture {
            .image-uploads {
              height: 45px;
              max-width: 45px;
              min-width: 45px;
            }

            .agree-icon {
              top: 30px;
              left: 30px;
            }
          }
        }

        .title-img {
          .picture {
            .agree-icon {
              .b-circle {
                height: 18px;
                width: 18px;

                i {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width:375px) {
  .notification {
    width: 100%;
    padding: 15px;

    .notification-card {
      .card-body {
        .title-img {
          .picture {
            .agree-icon {
              .b-circle {
                .tick {
                  font-size: 15px;

                }
              }
            }
          }
        }
      }
    }
  }
}