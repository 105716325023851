.border-gray{
    color: rgb(2, 212, 37);
    text-align: center;
    margin: 0 auto;
    font-size: 26px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    ;
    justify-content: center;
    font-size: 28px;
    
}