@import "../../assets/styles/variables.scss";

.opnion-section {
  margin-top: 200px;

  .for-section {
    position: relative;

    .for-arrow {
      color: $limeGreen;
      width: 250px;
      padding: 10px 30px;
      background: $lightGreen;
      position: absolute;
      right: 20px;
      text-transform: uppercase;
      -webkit-clip-path: polygon(0 0,
          90% 0,
          89% 0,
          100% 50%,
          90% 99%,
          91% 100%,
          0 100%);
      clip-path: polygon(0 0, 90% 0, 89% 0, 100% 50%, 90% 99%, 91% 100%, 0 100%);
    }
  }

  .f-right {
    float: right;
  }

  .against-section {
    position: relative;

    .against-arrow {
      color: $darkPink;
      width: 250px;
      padding: 10px 30px;
      background: $lightPink;
      position: absolute;
      text-transform: uppercase;
      left: 20px;
      -webkit-clip-path: polygon(12% 0,
          12% 0,
          100% 0,
          100% 100%,
          14% 99%,
          13% 100%,
          0 50%);
      clip-path: polygon(12% 0,
          12% 0,
          100% 0,
          100% 100%,
          14% 99%,
          13% 100%,
          0 50%);
    }
  }

  .br-1 {
    border-right: 1px solid $greenColor;
  }

  .mb-6 {
    margin-bottom: 60px;
  }

  .vr-line {
    background: $greenColor;
    width: 0.5px;
    height: 34px;
    position: absolute;
    right: -12px;
    top: 30px;
  }
}

.my-anchor-css-class {
  color: #86af8a;
  text-decoration: underline;
}

.for-section,
.against-section {
  margin-bottom: 60px;
}

@media(max-width:768px) {
  .opnion-section {

    .for-section .for-arrow,
    .against-section .against-arrow {
      width: 210px;
    }
  }
}

@media(max-width:480px) {
  .opnion-section {

    .for-section .for-arrow,
    .against-section .against-arrow {
      right: unset;
      width: 200px;
      padding: 5px 30px;
    }

    .for-section {
      margin-bottom: 55px;
    }

    .against-section {
      left: 235px;
      display: inline-block;
      margin-bottom: 50px;
      margin-top: 7px;

      .against-arrow {
        // bottom: 28px;
        width: 189px;
        left: -33px;
      }
    }

    .br-1 {
      border: 0px;
    }
  }
}

@media(max-width:375px) {
  .opnion-section {

    .for-section .for-arrow,
    .against-section .against-arrow {
      width: 150px;
    }

    .against-section {
      .against-arrow {
        left: -34px;
      }
    }
  }
}

@media(max-width:320px) {
  .opnion-section {

    .for-section .for-arrow,
    .against-section .against-arrow {
      width: 135px;
    }

    .against-section {
      .against-arrow {
        left: -75px;
      }
    }
  }
}
