@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import "../styles/variables.scss";

.App {
  background-color: $lightGray;
}

body {
  background: #fafafa;
  font-family: "Noto Sans", sans-serif;
}

.black {
  color: $black;
}

.category-checker {
  margin-left: 838px;
}

.popup-padding {
  padding: 7px;
}

.main-btn,
.primary-btn {
  color: $primaryColor;
  padding: 6px 20px;
  border: 1px solid $primaryColor !important;
  background-color: transparent;
  border-radius: 8px;
  font-size: 14px;
}

.primary-btn {
  color: $white;
  background-color: $primaryColor;
  border: 1px solid #2890284f !important;
}

.main-btn {
  &:hover {
    color: $white;
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    cursor: pointer;
  }
}

.primary-btn {
  &:hover {
    color: $primaryColor !important;
    background-color: $white;
    border: 1px solid $primaryColor;
    cursor: pointer;
  }
}

.ml-35 {
  margin-left: 35px;
}

.section-gap {
  padding: 30px 0;
}

.main-title {
  color: $primaryColor;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 15px;
  margin: 0;
}

.gray-text {
  cursor: pointer;
  font-size: 15px;
  color: $gray;
}

// .cw {
//   color: $faintGray;
// }
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
  color: #00324b;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.image-uploads {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.pr-15 {
  padding-right: 20px;
}

.card-section {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}

.popular-btn {
  outline: none;
  border: 0;
  border-radius: 5px;
  padding: 7px 24px;
  text-transform: capitalize;
  letter-spacing: 2px;
  color: $blue;
  background-color: $lightBlue;
  font-weight: 600;
  font-size: 13px;
  margin-right: 10px;
}

.category-btn {
  background: rgba($color: white, $alpha: 0.1);
}

.btn-for {
  background-color: #fff;
  color: $primaryColor !important;
  border: 1px solid $primaryColor;

  &:hover {
    color: $white !important;
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
}

.btn-for-active {
  background-color: $primaryColor;
  color: #fff !important;
  border: 1px solid $primaryColor;
  .cw {
    color: $white;
  }
}

.btn-against-active {
  background-color: $red;
  color: #fff !important;
  border: 1px solid $red;
  .cw {
    color: $white;
  }
}

.btn-against {
  color: $primaryColor !important;
  background-color: $white;
  border: 1px solid $primaryColor;

  &:hover {
    color: $white !important;
    background-color: $red;
    border-color: $red;
  }
}

.btn-:hover span,
.btn-against:hover span,
.btn-for:hover span {
  color: $white !important;
}

.btn-undecided:hover span {
  color: $white !important;
}

.btn- {
  color: $primaryColor !important;
  background-color: $white;
  border: 1px solid $primaryColor;
  font-size: 15px;
  padding: 5px 14px;

  &:hover {
    color: $white !important;
    background-color: $linkColor;
    border-color: $linkColor;
  }
}

.btn-undecided {
  color: $primaryColor !important;
  background-color: $white;
  border: 1px solid $primaryColor;

  &:hover {
    color: $white !important;
    background-color: #6182e0 !important;
    border-color: #6182e0 !important;
  }
}

.btn-undecided-active {
  background-color: #6182e0;
  color: #fff !important;
  border: 1px solid #6182e0;

  .cw {
    color: $white;
  }
}

.purple-btn {
  color: #f75be4ad;
  background: #f75be42e;
}

.pink-btn {
  color: #d00e56;
  background: #ff00001f;
}

.yellow-btn {
  color: #7763188c;
  background: #ff000012;
}

.blue-btn {
  color: #4e90d4;
  background: #a9e0e261;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-icon {
  font-size: 17px;
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #919191;

  &:hover {
    cursor: pointer;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
  }
}

.number {
  color: $select;
  padding: 0 0 0 7px;
  font-size: 12px;
}

.transform {
  transform: rotate(-90deg);
}

.like-icon {
  padding: 0 7px 0 0;
  color: #919191;
}

.time {
  color: #919191;
  font-size: 10px;
  font-weight: normal;
}

.against-icon {
  color: #919191;
  padding: 0px 7px 0px 0px;
}

.flex {
  display: flex;
}

.profile {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.circle {
  width: 16px;
  height: 16px;
  background: #86af8a;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 0;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.card-shadow {
  border: 1px solid #64af8c1f;
  box-shadow: 0px 2px 5px -1px #73ad9229;
}

.title {
  margin-top: 10px;
  // margin-left: 14px;
  font-size: 18px;
  color: $title-color;
  margin: 0;
  font-weight: 600;
}

.popup-padding {
  margin-top: 10px;

  padding: 10px;
}

.btn-block {
  .cancel-btn {
    background-color: #f9f9f9 !important;
    color: #919191 !important;
    padding: 10px 20px !important;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
  }
}

.popup-title {
  margin-bottom: 10px !important;
  margin-right: 60px !important;
}

.main-btn {
  margin: 0;
  background-color: #86af8a;
  color: #fff;
}

.content {
  font-size: 16px;
  color: $content-color;
  margin: 0;
}

.mid-content {
  font-size: 14px;
  color: $content-color;
  margin: 0;
}

.small-content {
  font-size: 12px;
  color: #3e67de;
  font-weight: 500;
  font-style: normal;
  margin: 0;
}

.font-500 {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.pl-30 {
  padding-left: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.banner {
  position: relative;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;

  .banner-text {
    font-size: 30px !important;
    font-weight: bold;
    color: $white;
    text-align: center;
    padding: 0 15px;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #6767674d;
    display: flex;
    justify-content: center;
  }
}

a {
  text-decoration: none;
  color: unset;

  &:hover {
    color: unset;
    text-decoration: none;
  }
}

.error {
  width: 100%;
  font-size: 12px;
  color: red;
}

.see-more {
  text-align: center;

  .popular-btn {
    color: $primaryColor;
    background-color: transparent;
    border: 1px solid $primaryColor;
    letter-spacing: 0;
    padding: 5px 15px;
  }
}

.avatar-upload {
  position: relative;
  margin: 5px auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar-edit {
    position: absolute;
    right: 120px;
    z-index: 1;
    top: 10px;

    input {
      display: none;

      + label {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }

        &:after {
          content: "";
          font-family: "FontAwesome";
          color: #757575;
          position: absolute;
          top: 10px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }

        i {
          position: initial;
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }
    }
  }

  .avatar-preview {
    width: 130px;
    height: 130px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    > div {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.btn-block {
  margin-top: 5px;
  display: flex !important;
  align-items: center;

  button {
    margin-right: 10px;
  }
}

.btn-count {
  display: flex;
  align-items: center;
  margin-left: 13px;

  button {
    margin-right: 0;
  }

  .count {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    color: $select;
  }
}

.icon-btn {
  border: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $select;
  color: $white;

  i {
    font-size: 12px;
  }
}

.Toastify__toast-container {
  z-index: 100000;
}

.scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    // display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $content-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
}

.jodit-workplace {
  background: #fff;
}

@media (max-width: 480px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }

  .col-sm-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }

  .col-sm-9 {
    flex: 0 0 74.99%;
    max-width: 74.99%;
  }

  .col-sm-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }

  .col-sm-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-5 {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }

  .col-sm-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }

  .col-sm-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
}

.MuiTab-wrapper {
  font-style: normal;
  color: #919191;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.field-content {
  margin: 40px 0px;

  input.input-field {
    margin-bottom: 0px;
  }

  .form-group {
    margin-bottom: 15px;
  }
}

.bodyheight {
  min-height: calc(100vh - 200px);
}

.d-none {
  display: none;
}

.pt-15 {
  padding-top: 15px;
}

button:disabled,
button[disabled] {
  cursor: not-allowed !important;
}

button {
  cursor: pointer;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

// .hidden-scroll {
//   overflow: hidden;
//   height: 100vh;
// }

@media (max-width: 768px) {
  .main-title {
    font-size: 22px;
  }

  .popular-btn {
    font-size: 12px;
  }

  .gray-text {
    font-size: 14px;
  }

  .content {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .main-title {
    font-size: 18px;
  }

  .section-gap {
    padding: 20px 0;
  }

  .gray-text {
    font-size: 12px;
  }

  .popular-btn {
    padding: 7px 15px;
  }

  .primary-btn {
    font-size: 12px;
    padding: 6px 15px;
  }

  .title {
    font-size: 14px;
  }

  .content {
    font-size: 12px;
  }

  .banner {
    .banner-overlay {
      height: 77%;
    }
  }
}

@media (max-width: 375px) {
  .card-section {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .btn- {
    display: none;
  }
}

