@import "../../assets/styles/variables.scss";

.sign-up-section {
  .scroll-bar{
    height: 520px !important;
  }
  .popup {
    label {
      font-size: 12px !important;
    }

    .input-field {
      font-size: 12px !important;
    }

    .cb {
      color: $linkColor;
    }

    .flex-start {
      float: left;
      padding-left: 25px;
    }

    .eye {
      padding: 2px 0 0 !important;
    }

    .cb {
      color: #2d74e3;
    }
  }

  .form-section {
    .scroll-bar {}
  }
}

.signup-section {
  padding: 3px;
}

.image-upload {
  float: left;
  margin-bottom: 15px;
}

.log-btn {
  color: #2e2e2e;
  font-size: 10px;
  margin-left: 5px;
}

.text-form {
  margin-left: -23px !important;
}

.close-btn {
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  padding-bottom: 5px;
}

.accept {
  margin-left: 10px;
  color: $select;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
}

.term-condition {
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  color: #3172d4;
}

.accept-terms {
  display: flex;
}