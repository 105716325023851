@import "../../assets/styles/variables.scss";

.footer {
  background-color: $primaryColor;
  padding: 30px 0 10px 0 !important;

  .text {
    color: $white;
    font-size: 18px;
  }

  .footer-content {
    margin-bottom: 15px;

    span {
      display: flex;
    }

    a {
      text-decoration: none;
    }
  }

  .social-media {
    border-top: 1px solid $white;
    padding-top: 10px;

    .media-icons {
      display: flex;
      justify-content: flex-end;

      .icons {
        color: $white;
        font-size: 25px;
        padding: 0 10px;
      }
    }
  }

  .line-height {
    line-height: 1.2;
  }

  .hidden-block {
    display: none;
  }
}

@media (max-width: 480px) {
  .footer {
    .hidden-block {
      display: block;
    }

    .footer-content {
      margin-bottom: 5px;
    }

    .text {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .social-media{
      padding-top: 7px;
    }
  }
}

.footer-part {
  position: fixed;
  bottom: 0;
  width: 100%;
}