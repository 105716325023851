.contents {
  text-align: left;
}
.input-field {
  padding: 7px 10px;
  border: 1px;
  background: #f5f5f5;
  width: 100%;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 10px;
}
.form-section {
  padding: 20px;
  height: 100%;

}
label {
  width:100%;
  margin-bottom: 3px;
}

.edit-popup{
  max-height: 600px;
  .edit-modal{
    height: 100%;
  }
}