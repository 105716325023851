@import "../../assets/styles/variables.scss";

.stance-section {
  position: relative;

  .banner {
    // background-image: url("https://img.rasset.ie/000e6650-1600.jpg");
    background-size: cover;
    width: 100%;
    background-position: center;

    .agree {
      button {
        color: $primaryColor;
      }

      .btn- {
        color: $white !important;
        background-color: $linkColor;
        border-color: $linkColor;

        i,
        span {
          color: $white;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .stance-section {
    .banner {
      text-align: center;
    }

    .agree {
      button {
        margin-bottom: 10px;
      }
    }
  }
}