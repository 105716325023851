@import "../../assets/styles/variables.scss";


.not-found-img {
    padding: 40px 0;

    .img-title {
        color: $fontGray;
        font-weight: 500;
        font-size: 16px;
    }

    .img-highlight {
        font-size: 20px;
        text-transform: capitalize;
    }

    img {
        width: 25%;
        // height: 230px;
        padding: 0 0 20px 0;
    }
}