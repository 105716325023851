@import "../../assets/styles/variables.scss";

.nav-bar {
  background-color: $white;
  .nav-link{
    margin-right: 20px;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
  }

  .dropdown-toggle {
    padding: 0;

    &::after {
      display: none;
    }
  }

  .nav-bar-left-side {
    display: flex;
    align-items: center;
  }

  .search {
    color: #919191;
  }

  .navbar-brand {
    font-size: 20px;
    font-weight: 500;
    color: $black !important;

    .logo {
      font-size: 16px;
      color: #617562;
      font-weight: bold;
      line-height: 1;
    }
  }

  .nav-title {
    font-weight: bold;
    color: $primaryColor;
  }

  .nav-link {
    font-size: 16px;
  }

  .logo {
    display: flex;
    flex-direction: column;
  }

  .navbar {
    align-items: center;
    font-weight: 500;
    justify-content: space-between;
  }

  .nav-item {
    .nav-link {
      // cursor: pointer;
      font-weight: $bold;
      color: #617562;
    }
  }

  .nav-right {
    justify-content: flex-end;
  }

  .nav-btns {
    display: flex;
    align-items: center;
    margin: 0px 10px 0 15px;

    button {
      border: 1px solid transparent;
      outline: none;
      color: #fff;
    }
  }

  .moderator-nav {
    cursor: pointer;
  }

  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: $primaryColor;
    text-decoration: none;
  }

  .search-bar {
    border: 1px solid #c3bebe;
    border-radius: 8px;
    padding: 6px 10px 6px 30px;
    margin-bottom: 5px;

    input {
      border: none;
      font-size: 14px;
      outline: none;
      color: $black;
      width: 200px;
    }
  }

  .search-bar {
    position: relative;

    .search {
      font-size: 18px;
      position: absolute;
      z-index: 1;
      left: 9px;
    }
  }

  .navbar-light .navbar-nav .active>.nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show>.nav-link {
    color: $primaryColor;
  }
}

.signup-google {
  margin-bottom: 11px;
  border: 1px solid blue;
}

.signup-fb {
  margin-bottom: 11px;
  border: 1px solid blue;
}

.forgot-pw {
  color: #86af8a;
  margin-top: 10px;
  margin-left: 11px;
  margin-right: 135px;
  margin-bottom: 19px;
}

.notify-section {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 15px;

  .section-gap {
    padding: 0;
  }

  .notification {
    position: absolute;
    top: 55px;
    right: -75px;
    z-index: 1200;
    min-width: 400px;
    max-width: 400px;
    padding: 15px;

    .notification-section {
      .notification-image {
        img {
          height: 40px;
          width: 40px;
        }

        .verify-icon {
          height: 15px;
          width: 15px;
          top: 23px;

          i {
            font-size: 9px;
          }

          .tick {
            font-size: 13px;
          }
        }
      }

      .notification-block {
        .small-content {
          font-size: 13px;
          margin-bottom: 10px;
        }

        .time {
          margin: 0;
        }
      }
    }
  }

  i {
    font-size: 30px;
    color: $primaryColor;

  }

  .bell-icon {
    position: relative;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .bell-no {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    background: red;
    color: white;
    border-radius: 50%;
    font-weight: 800;
    height: 14px;
    width: 14px;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    // display: none;
  }

  .font-25 {
    font-size: 25px;
  }

  .card-section {
    width: 400px;
    float: right;
    position: absolute;
    z-index: 100;
    left: -210px;
    top: 32px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;

    .notification-section {
      padding: 0;
    }
  }

  .notification-content {
    position: relative;

    .section-gap {
      padding: 0;
    }

    button {
      display: none;
    }
  }

  .log-section {
    .card-section {
      width: 350px;
      right: 0;
      top: 16px;
      left: unset;

      .my-profile {
        align-items: center;
        border-bottom: 1px solid #80808040;
        padding-bottom: 10px;

        .log-img,
        .log-icon {
          min-height: 45px;
          min-width: 45px;
          height: 45px;
          width: 45px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          background: $primaryColor;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .profile-text {
          margin-left: 10px;
        }
      }
    }

    .log-out {
      display: flex;
      align-items: center;
      padding-top: 10px;

      &:hover {
        cursor: pointer;
      }

      .log-out-icon {
        background: $red;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        i {
          color: $white;
          font-size: 14px;
        }
      }

      .mid-content {
        color: $title-color;
        font-weight: 600;
      }
    }
  }

  .see-more {
    display: none;
  }

  .bx-chevron-down {
    &:hover {
      cursor: pointer;
    }
  }

  .nav-profile {
    position: relative;
    display: flex;
    align-items: center;

    img.profile-img,
    .user-icon {
      height: 36px;
      width: 36px;
      min-height: 36px;
      min-width: 36px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      background: $primaryColor;
      color: $white;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

  }

  .hide {
    display: none;
  }
}

.nav-header {
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .nav-bar-bg {
    .nav-link {
      font-size: 15px;
      padding-left: 0 !important;
    }

    .nav-item {
      padding: 0 5px;
    }

    .search-bar input {
      width: 185px;
    }

    .navbar-nav {
      padding-right: 3px;
    }

    .notify-section {
      .bell-icon {
        padding: 0 10px;
      }

      .bell-no {
        position: absolute;
        left: 22px;
      }

      .card-section {
        left: -303px;
      }

      .log-section {
        .card-section {
          left: -250px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .nav-bar-bg {
    .search-bar {
      display: none;
    }
  }
}

@media (max-width: 1930px) and (min-width: 1000px) {
  .nav-bar-bg {
    .notify-section {
      .log-section {
        .card-section {
          width: 350px;
          left: -137px;
          top: 58px;
        }
      }
    }
  }
}

@media(max-width:768px) {
  .navbar {
    position: relative;
    display: block;
    padding: 15px 0;

    .navbar-toggler {
      position: absolute;
      top: 19px;
    }

    .notify-section {
      position: absolute;
      top: 12px;
      right: 0;

      .log-section {
        .card-section {
          width: 315px;
          top: 11px;
        }
      }
    }

    .navbar-toggler {
      padding: 0;
      border: none;

      &:focus {
        outline: unset;
        box-shadow: none;
      }
    }
  }

  .nav-bar {
    .navbar-brand {
      .logo {
        margin-left: 50px;
        margin-bottom: 0;
        font-size: 14px;
      }
    }

    .nav-btns {
      margin: 8px 0 15px 0;
    }

    .nav-link {
      font-size: 14px;
      padding: 5px 0;
    }
  }
}

@media(max-width:480px) {

  //reverse navbar as per figma design
  // .nav-bar{
  //   .nav-btns{
  //     margin: 7px 0 14px 0;
  //   }
  //   .navbar{
  //     .nav-bar-left-side{
  //       display: flex;
  //       justify-content: space-between;
  //       // flex-direction: row-reverse; 
  //       width: calc(100% - 115px);
  //       .navbar-brand{
  //         .logo{
  //           margin-left: 0;
  //         }
  //       }
  //     }
  //     .navbar-toggler{
  //       position: unset;
  //     }
  //   }
  // }
  .notify-section {
    .log-section {
      .card-section {
        width: 250px;

        .my-profile {
          img {
            height: 40px;
            width: 40px;
          }
        }
      }

      .log-out {
        .log-out-icon {
          height: 20px;
          width: 20px;

          i {
            font-size: 12px;
          }
        }
      }

      .title {
        font-size: 14px;
      }

      .mid-content {
        font-size: 12px;
      }
    }
  }
}

@media(max-width: 411px) and (min-width: 361px) {
  .nav-bar {
    .notify-section {
      .notification {
        right: -160px;
        max-width: 350px;
        min-width: 350px;
      }
    }
  }
}


@media(max-width: 360px) {
  .nav-bar {
    .notify-section {
      .notification {
        right: -63px;
      }
    }
  }
}

@media(max-width: 375px) {
  .nav-bar {
    .navbar {
      padding: 10px 0;

      .navbar-toggler {
        top: 14px;
      }
    }

    .nav-link {
      font-size: 12px;
      padding: 3px 0;
    }

    .notify-section {
      i {
        font-size: 26px;
      }

      .bell-no {
        height: 12px;
        width: 12px;
        font-size: 7px;
      }

      .nav-profile {
        img.profile-img {
          height: 30px;
          width: 30px;
        }

        i {
          font-size: 22px;
        }
      }


    }

    .notify-section {
      .notification {
        min-width: 300px;
        max-width: 300px;
        top: 45px;
        right: -145px;
      }
    }
  }
}

@media(max-width:320px) {
  .notify-section {
    .log-section {
      .card-section {
        width: 230px;
      }
    }

    .notification {
      min-width: 280px;
      max-width: 280px;
    }
  }
}

@media(max-width:480px) {
  .primary-btn {
    margin-right: 5px;
  }


}

@media(max-width:480px) {
  .notify-section {
    .notification {
      right: -175px;
    }
  }

}



@media(max-width:280px) {
  .nav-bar {
    .notify-section {
      .notification {
        right: -71px;
        min-width: 275px;
        max-width: 275px;
      }
    }
  }
}