@import "../../assets/styles/variables.scss";

.thankyou-section {
  .card-section {
    height: 400px;
  }
  .thankyou-content {
    text-align: center;
    padding: 10px;
    .main-title {
      font-size: 20px;
      font-family: "noto sans";
      margin-bottom: 0;
      line-height: 27px;
      font-weight: 400;
    }

    h2 {
      font-size: 16px;
      color: $fontGray;
      margin-bottom: 20px;
    }

    p {
      color: $fontGray;
      font-size: 15px;
      line-height: 25px;
      font-family: "Noto Sans";
    }

    a {
      color: $primaryColor;
      font-size: 15px;
    }
  }
}
